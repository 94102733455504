<!--eslint-disable vue/no-v-html-->
<template>
  <v-container
    class="signin-form leaf--text font-weight-light px-5"
  >
    <v-row
      class="justify-center"
    >
      <v-col
        cols="12"
        class="d-flex justify-center"
        :style="{'margin-top': '10vmin'}"
      >
        <CPDLogo width="35vmin" />
      </v-col>
      <v-col
        cols="12"
        style="max-width: 520px"
        :px-5="$vuetify.breakpoint.smAndUp"
      >
        <ValidationObserver
          ref="observer"
          v-slot="{ handleSubmit, errors }"
        >
          <v-form
            ref="form"
            dark
            color="leaf"
            class="leaf--text form start-form"
            :action="showSignUpFields ? `${$baseURL}/users` : `${$baseURL}/session`"
            method="POST"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <input
              hidden
              name="onSuccess"
              :value="onSuccess"
            >
            <input
              hidden
              name="onFailure"
              :value="onFailure"
            >
            <ValidationProvider
              v-slot="{ errors }"
              name="Email"
              rules="required|email"
            >
              <v-text-field
                v-model="form.email"
                type="email"
                dark
                class="leaf--text zoom"
                placeholder="Email"
                :error-messages="errors"
                data-vv-name="email"
                name="email"
                autocomplete="email"
                required
                @input="passwordResetEmail = $event"
              />
            </ValidationProvider>
            <v-expand-transition>
              <ValidationProvider
                v-if="showSignUpFields"
                v-slot="{ errors }"
                name="Name"
                rules="required"
              >
                <v-text-field
                  v-model="form.name"
                  dark
                  autocomplete="name"
                  class="leaf--text zoom"
                  style="overflow-y: hidden;"
                  placeholder="Name"
                  :error-messages="errors"
                  data-vv-name="name"
                  name="name"
                  required
                />
              </ValidationProvider>
            </v-expand-transition>

            <v-expand-transition>
              <ValidationProvider
                v-if="showSignUpFields"
                v-slot="{ errors }"
                name="Company"
                rules="required"
              >
                <v-text-field
                  v-model="form.company"
                  dark
                  autocomplete="organization"
                  class="leaf--text zoom"
                  style="overflow-y: hidden;"
                  placeholder="Company"
                  :error-messages="errors"
                  data-vv-name="company"
                  name="company"
                  required
                />
              </ValidationProvider>
            </v-expand-transition>
            <v-expand-transition>
              <ValidationProvider
                v-if="showSignUpFields"
                v-slot="{ errors }"
                name="Country"
                rules="required"
              >
                <v-autocomplete
                  v-model="form.country"
                  :items="countries"
                  color="white"
                  hide-selected
                  item-text="name"
                  dark
                  autocomplete="off"
                  placeholder="Country"
                  class="leaf--text zoom"
                  data-vv-name="country"
                  name="country"
                  :error-messages="errors"
                  return-object
                />
              </ValidationProvider>
            </v-expand-transition>
            <ValidationProvider
              v-slot="{ errors }"
              name="Password"
              rules="required|min:6"
            >
              <v-text-field
                v-model="form.password"
                dark
                class="leaf--text zoom"
                placeholder="Password"
                :autocomplete="showSignUpFields ? 'new-password' : 'current-password'"
                type="password"
                :error-messages="errors"
                data-vv-name="password"
                name="password"
              />
            </ValidationProvider>
            <v-dialog
              v-model="passwordDialog"
              width="500"
            >
              <template v-slot:activator="{ on }">
                <v-col v-if="!showSignUpFields">
                  <a
                    class="mb-4 leaf--text d-block text-center body-2"
                    v-on="on"
                  >Forgot your password?</a>
                </v-col>
              </template>
              <v-card>
                <v-card-title
                  class="headline grey lighten-2 py-3"
                  primary-title
                >
                  Reset password
                </v-card-title>
                <v-card-text>
                  <v-tabs
                    v-model="resetStep"
                    class="reset-pass-tabs"
                  >
                    <v-tab>1</v-tab>
                    <v-tab>2</v-tab>
                    <v-tab-item>
                      <v-layout
                        row
                        align-center
                        style="min-height: 180px;"
                      >
                        <v-col>
                          <v-form @submit.prevent="resetPassword()">
                            Enter the email address you used to sign up. We'll email you a link to reset your password.
                            <ValidationProvider
                              v-slot="{ errors }"
                              vid="email"
                              name="Email"
                              rules="required|email"
                            >
                              <v-text-field
                                v-model="passwordResetEmail"
                                placeholder="Enter your email address"
                              />
                            </ValidationProvider>
                            <p
                              v-if="passwordResetError"
                              class="mb-3 red--text text-center"
                              style="font-size: 13px;"
                            >
                              {{ passwordResetError }}
                            </p>
                            <v-btn
                              type="submit"
                              color="primary"
                              class="d-block mx-auto"
                              :loading="resetting"
                            >
                              Send email
                            </v-btn>
                          </v-form>
                        </v-col>
                      </v-layout>
                    </v-tab-item>
                    <v-tab-item>
                      <v-layout
                        row
                        align-center
                        style="min-height: 170px;"
                        class="text-center"
                      >
                        <v-col>
                          <div
                            class="headline d-flex align-center mx-auto my-5"
                            style="width: fit-content;"
                          >
                            On its way&nbsp;
                            <v-icon
                              medium
                              color="#000"
                            >
                              mdi-rocket
                            </v-icon>
                          </div>
                          <p>If you have a Pathfinder account, you'll have received an email from us.</p>
                          <p>
                            Click the link in your email to reset your password <br> (don't forget to check your junk).
                          </p>
                        </v-col>
                      </v-layout>
                    </v-tab-item>
                  </v-tabs>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-expand-transition>
              <div
                v-if="showSignUpFields"
                class="d-flex align-center"
              >
                <p class="ma-0">
                  I agree to the
                  <span
                    class="leaf--text"
                    style="text-decoration: underline; cursor: pointer;"
                    @click="showTermsOfService = true"
                  >Terms of Service</span>.
                </p>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Terms Of Service"
                  :rules="{ required: { allowFalse: false } }"
                >
                  <v-checkbox
                    id="terms"
                    v-model="form.terms"
                    dark
                    class="px-3"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </div>
            </v-expand-transition>
            <v-dialog
              v-model="showTermsOfService"
              width="600"
              :fullscreen="$vuetify.breakpoint.xsOnly"
            >
              <v-card>
                <v-card-title
                  class="headline"
                  primary-title
                >
                  Terms of Service
                </v-card-title>
                <v-divider />

                <v-card-text
                  class="pt-2 terms-text"
                  v-html="termsText"
                />

                <v-divider />

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    text
                    @click="showTermsOfService = false"
                  >
                    Dismiss
                  </v-btn>
                  <v-btn
                    color="shamrock"
                    text
                    @click="agreeToTerms()"
                  >
                    I accept
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-btn
              large
              block
              class="elevation-0 px-5 text-uppercase mt-4 sign-in-button"
              color="leaf"
              :class="[{'noevents': submitting ? true : false}, 'submit-button']"
              :outlined="form.email && form.password ? false : true"
              type="submit"
              :loading="submitting"
            >
              <span
                :style="{
                  'color': form.email && form.password ? 'var(--v-onyx-base)' : 'var(--v-leaf-base)',
                  'border-color': form.email && form.password ? 'var(--v-onyx-base)' : 'var(--v-leaf-base)'
                }"
              >
                {{ showSignUpFields ? "Sign Up" : "Sign In" }}
              </span>
            </v-btn>
            <p
              v-if="showSignUpFields"
              class="body text-center mt-7 mb-4"
            >
              {{ showSignUpFields ? "Already have an account?" : "Don't yet have an account?" }}
            </p>
            <v-btn
              class="px-5 mt-0 sign-up-button"
              :class="{ 'mt-7': !showSignUpFields }"
              text
              block
              large
              color="leaf"
              @click.prevent="showSignUpFields = !showSignUpFields"
            >
              {{ showSignUpFields ? "Sign In" : "Create account" }}
            </v-btn>
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations, mapActions, createNamespacedHelpers } from 'vuex'
import { captureException, captureMessage } from '@sentry/vue'
import CPDLogo from '@/components/application/CPDLogo'
import termsText from '@/assets/terms.md'
import countries from './countries.json'
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
const { mapActions: mapAuthActions, mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')

export default {
  $_veeValidate: {
    validator: 'new'
  },
  name: 'SignIn',
  components: {
    CPDLogo,
    ValidationObserver,
    ValidationProvider
  },
  props: [],
  data () {
    return {
      countries,
      showSignUpFields: false,
      showTermsOfService: false,
      loading: false,
      submitting: false,
      typ: undefined,
      passwordDialog: false,
      passwordResetEmail: null,
      resetStep: 0,
      passwordResetError: null,
      resetting: false,
      form: {
        email: undefined,
        password: undefined,
        remember: true,
        terms: false
      },
      dictionary: {
        attributes: {
          email: 'E-mail Address'
        },
        fields: {
          title: {
            required: () => "Don't forget to add a title"
          },
          'Terms Of Service': {
            required: () => 'You must agree to the terms to continue.'
          }
        }
      },
      termsText
    }
  },
  computed: {
    ...mapAuthGetters(['user']),

    isFormValid () {
      return !Object.keys(this.fields).some(key => this.fields[key].invalid)
    },

    onFailure () {
      if (this.showSignUpFields) {
        const params = new URLSearchParams(window.location.search)
        params.delete('loginFailed')
        params.set('signUpFailed', 1)
        return `${window.location.origin}${window.location.pathname}?${params.toString()}`
      } else {
        const params = new URLSearchParams(window.location.search)
        params.delete('loginFailed')
        params.set('loginFailed', 1)
        return `${window.location.origin}${window.location.pathname}?${params.toString()}`
      }
    },

    onSuccess () {
      const params = new URLSearchParams(window.location.search)
      if (params.has('redirect')) {
        return params.get('redirect')
      } else {
        if (this.$store.state.isOnboarding) {
          return `${window.location.origin}/projects/new`
        } else {
          return `${window.location.origin}/projects`
        }
      }
    }

  },
  watch: {
    passwordDialog (newVal, oldVal) {
      if (oldVal && !newVal) {
        this.resetStep = 0
      }
    }
  },
  mounted () {
    localize('en', this.dictionary)
    const params = new URLSearchParams(window.location.search)
    if (+params.get('loginFailed') === 1) {
      this.showSnackbar('Incorrect username or password, please try again or click "Forgot your password".')
    }
    if (+params.get('signUpFailed') === 1) {
      this.showSnackbar('Something went wrong, a user with this email may already exist, please try signing in.')
    }
  },
  methods: {
    ...mapMutations(['hideSnackbar', 'setToolbarColor']),
    ...mapActions(['showSnackbar']),
    ...mapAuthActions(['registerNewUser', 'loginUser', 'sendPasswordResetEmail']),

    agreeToTerms () {
      this.form.terms = true
      this.showTermsOfService = false
    },

    async onCompletion () {
      const params = new URLSearchParams(window.location.search)
      if (params.has('redirect')) {
        this.$router.replace(params.get('redirect'))
      } else {
        if (this.$store.state.isOnboarding) {
          this.$router.replace({ name: 'new-project' })
        } else {
          this.$router.replace({ name: 'projects' })
        }
      }
    },

    async resetPassword () {
      this.resetting = true
      try {
        await this.sendPasswordResetEmail({ email: this.passwordResetEmail })
        this.passwordResetError = null
        this.resetStep = 1
      } catch (error) {
        this.passwordResetError = 'Whoops, something went wrong. Please try again.'
      } finally {
        this.resetting = false
      }
    },

    async signIn () {
      try {
        const { email, password } = this.form
        await this.loginUser({ email, password })
        this.onCompletion()
      } catch (error) {
        let isAuthError = false
        if (!error?.response) {
          isAuthError = true
          captureMessage(`No category found with name ${error}`)
          captureException(error)
        } else if (error?.response.status === 401) {
          isAuthError = error.response.status === 401
        }
        this.showSnackbar(isAuthError ? 'Your username / password was incorrect' : 'Something went wrong. Please try again or get in touch.')
        this.submitting = false
        if (!isAuthError) {
          throw error
        }
      }
    },
    async signUp () {
      const { email, password, name, company, country } = this.form
      try {
        await this.registerNewUser({ email, password, name, company, country })
        this.showSnackbar('Welcome!')
        this.onCompletion()
      } catch (error) {
        this.showSnackbar('Something went wrong. There may already be a user with that email. Please try signing in, or get in touch.')
      } finally {
        this.submitting = false
      }
    },

    async onSubmit () {
      this.submitting = true
      if (!this.showSignUpFields) {
        await this.signIn()
      } else {
        await this.signUp()
      }
      this.loading = false
      this.submitting = false
      this.form.password = ''
    },

    clear () {
      this.email = undefined
      this.password = undefined
      this.typ = null
      this.$refs.observer.reset()
    }

  }
}
</script>

<style lang="scss">

.signin-form {
  .v-input, .v-autocomplete {
    input {
      font-size: 1.5em;
      font-weight: 300;
      padding: 25px 0;
      &::placeholder {
        color: var(--v-leaf-darken4) !important;
      }
    }
  }
  .v-input--checkbox .v-messages {
    max-width: 200px;
  }
}

.terms-text {
  h1, h2, h3, h4 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.reset-pass-tabs {
  .v-tabs-bar {
    display: none;
  }
}

.form .theme--dark.v-input:not(.v-input--is-disabled) input{
  color: var(--v-leaf-base) !important;
}

.sign-in-button .v-btn--loader span, .start-form .v-btn--loader span{
  color: var(--v-onyx-base) !important;
}

.form .v-messages__message{
  color: var(--v-shamrock-base) !important;
}
</style>
