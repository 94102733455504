var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"signin-form leaf--text font-weight-light px-5"},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{staticClass:"d-flex justify-center",style:({'margin-top': '10vmin'}),attrs:{"cols":"12"}},[_c('CPDLogo',{attrs:{"width":"35vmin"}})],1),_c('v-col',{staticStyle:{"max-width":"520px"},attrs:{"cols":"12","px-5":_vm.$vuetify.breakpoint.smAndUp}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var errors = ref.errors;
return [_c('v-form',{ref:"form",staticClass:"leaf--text form start-form",attrs:{"dark":"","color":"leaf","action":_vm.showSignUpFields ? (_vm.$baseURL + "/users") : (_vm.$baseURL + "/session"),"method":"POST"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('input',{attrs:{"hidden":"","name":"onSuccess"},domProps:{"value":_vm.onSuccess}}),_c('input',{attrs:{"hidden":"","name":"onFailure"},domProps:{"value":_vm.onFailure}}),_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"leaf--text zoom",attrs:{"type":"email","dark":"","placeholder":"Email","error-messages":errors,"data-vv-name":"email","name":"email","autocomplete":"email","required":""},on:{"input":function($event){_vm.passwordResetEmail = $event}},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('v-expand-transition',[(_vm.showSignUpFields)?_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"leaf--text zoom",staticStyle:{"overflow-y":"hidden"},attrs:{"dark":"","autocomplete":"name","placeholder":"Name","error-messages":errors,"data-vv-name":"name","name":"name","required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)}):_vm._e()],1),_c('v-expand-transition',[(_vm.showSignUpFields)?_c('ValidationProvider',{attrs:{"name":"Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"leaf--text zoom",staticStyle:{"overflow-y":"hidden"},attrs:{"dark":"","autocomplete":"organization","placeholder":"Company","error-messages":errors,"data-vv-name":"company","name":"company","required":""},model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}})]}}],null,true)}):_vm._e()],1),_c('v-expand-transition',[(_vm.showSignUpFields)?_c('ValidationProvider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"leaf--text zoom",attrs:{"items":_vm.countries,"color":"white","hide-selected":"","item-text":"name","dark":"","autocomplete":"off","placeholder":"Country","data-vv-name":"country","name":"country","error-messages":errors,"return-object":""},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}})]}}],null,true)}):_vm._e()],1),_c('ValidationProvider',{attrs:{"name":"Password","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"leaf--text zoom",attrs:{"dark":"","placeholder":"Password","autocomplete":_vm.showSignUpFields ? 'new-password' : 'current-password',"type":"password","error-messages":errors,"data-vv-name":"password","name":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.showSignUpFields)?_c('v-col',[_c('a',_vm._g({staticClass:"mb-4 leaf--text d-block text-center body-2"},on),[_vm._v("Forgot your password?")])]):_vm._e()]}}],null,true),model:{value:(_vm.passwordDialog),callback:function ($$v) {_vm.passwordDialog=$$v},expression:"passwordDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2 py-3",attrs:{"primary-title":""}},[_vm._v(" Reset password ")]),_c('v-card-text',[_c('v-tabs',{staticClass:"reset-pass-tabs",model:{value:(_vm.resetStep),callback:function ($$v) {_vm.resetStep=$$v},expression:"resetStep"}},[_c('v-tab',[_vm._v("1")]),_c('v-tab',[_vm._v("2")]),_c('v-tab-item',[_c('v-layout',{staticStyle:{"min-height":"180px"},attrs:{"row":"","align-center":""}},[_c('v-col',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.resetPassword()}}},[_vm._v(" Enter the email address you used to sign up. We'll email you a link to reset your password. "),_c('ValidationProvider',{attrs:{"vid":"email","name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Enter your email address"},model:{value:(_vm.passwordResetEmail),callback:function ($$v) {_vm.passwordResetEmail=$$v},expression:"passwordResetEmail"}})]}}],null,true)}),(_vm.passwordResetError)?_c('p',{staticClass:"mb-3 red--text text-center",staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.passwordResetError)+" ")]):_vm._e(),_c('v-btn',{staticClass:"d-block mx-auto",attrs:{"type":"submit","color":"primary","loading":_vm.resetting}},[_vm._v(" Send email ")])],1)],1)],1)],1),_c('v-tab-item',[_c('v-layout',{staticClass:"text-center",staticStyle:{"min-height":"170px"},attrs:{"row":"","align-center":""}},[_c('v-col',[_c('div',{staticClass:"headline d-flex align-center mx-auto my-5",staticStyle:{"width":"fit-content"}},[_vm._v(" On its way  "),_c('v-icon',{attrs:{"medium":"","color":"#000"}},[_vm._v(" mdi-rocket ")])],1),_c('p',[_vm._v("If you have a Pathfinder account, you'll have received an email from us.")]),_c('p',[_vm._v(" Click the link in your email to reset your password "),_c('br'),_vm._v(" (don't forget to check your junk). ")])])],1)],1)],1)],1)],1)],1),_c('v-expand-transition',[(_vm.showSignUpFields)?_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"ma-0"},[_vm._v(" I agree to the "),_c('span',{staticClass:"leaf--text",staticStyle:{"text-decoration":"underline","cursor":"pointer"},on:{"click":function($event){_vm.showTermsOfService = true}}},[_vm._v("Terms of Service")]),_vm._v(". ")]),_c('ValidationProvider',{attrs:{"name":"Terms Of Service","rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"px-3",attrs:{"id":"terms","dark":"","error-messages":errors},model:{value:(_vm.form.terms),callback:function ($$v) {_vm.$set(_vm.form, "terms", $$v)},expression:"form.terms"}})]}}],null,true)})],1):_vm._e()]),_c('v-dialog',{attrs:{"width":"600","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.showTermsOfService),callback:function ($$v) {_vm.showTermsOfService=$$v},expression:"showTermsOfService"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline",attrs:{"primary-title":""}},[_vm._v(" Terms of Service ")]),_c('v-divider'),_c('v-card-text',{staticClass:"pt-2 terms-text",domProps:{"innerHTML":_vm._s(_vm.termsText)}}),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.showTermsOfService = false}}},[_vm._v(" Dismiss ")]),_c('v-btn',{attrs:{"color":"shamrock","text":""},on:{"click":function($event){return _vm.agreeToTerms()}}},[_vm._v(" I accept ")])],1)],1)],1),_c('v-btn',{staticClass:"elevation-0 px-5 text-uppercase mt-4 sign-in-button",class:[{'noevents': _vm.submitting ? true : false}, 'submit-button'],attrs:{"large":"","block":"","color":"leaf","outlined":_vm.form.email && _vm.form.password ? false : true,"type":"submit","loading":_vm.submitting}},[_c('span',{style:({
                'color': _vm.form.email && _vm.form.password ? 'var(--v-onyx-base)' : 'var(--v-leaf-base)',
                'border-color': _vm.form.email && _vm.form.password ? 'var(--v-onyx-base)' : 'var(--v-leaf-base)'
              })},[_vm._v(" "+_vm._s(_vm.showSignUpFields ? "Sign Up" : "Sign In")+" ")])]),(_vm.showSignUpFields)?_c('p',{staticClass:"body text-center mt-7 mb-4"},[_vm._v(" "+_vm._s(_vm.showSignUpFields ? "Already have an account?" : "Don't yet have an account?")+" ")]):_vm._e(),_c('v-btn',{staticClass:"px-5 mt-0 sign-up-button",class:{ 'mt-7': !_vm.showSignUpFields },attrs:{"text":"","block":"","large":"","color":"leaf"},on:{"click":function($event){$event.preventDefault();_vm.showSignUpFields = !_vm.showSignUpFields}}},[_vm._v(" "+_vm._s(_vm.showSignUpFields ? "Sign In" : "Create account")+" ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }